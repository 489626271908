import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Box } from '@mui/material';

interface AtsResumeCheckerPopupProps {
    open: boolean;
    onClose: () => void;
}

const AtsResumeCheckerPopup: React.FC<AtsResumeCheckerPopupProps> = ({ open, onClose }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [jobDescription, setJobDescription] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleSubmit = () => {
        if (selectedFile && jobDescription) {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('jobDescription', jobDescription);

            fetch('http://localhost:4000/ats-checker', {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
                console.log('ATS Check Result:', data);
                // Handle the result (e.g., display a message to the user)
                setLoading(false);
                navigate('/ats-check-result');
                onClose();
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);
            });
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>ATS Resume Checker</DialogTitle>
            <DialogContent>
                <input type="file" onChange={handleFileChange} />
                <TextField
                    label="Job Description"
                    multiline
                    rows={4}
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                {loading && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>Cancel</Button>
                <Button onClick={handleSubmit} color="primary" disabled={loading}>
                    {loading ? 'Submitting...' : 'Submit'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AtsResumeCheckerPopup;