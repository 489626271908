import React, { useState } from 'react';
import { Typography, Button, Menu, MenuItem, AppBar, IconButton, Toolbar, Box } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { ILoggedinUserData } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';
import { removeToken } from '../auth/storeToken';

const Header = () => {
  const dispatch = useDispatch();
  const loggedInUserData: ILoggedinUserData = useSelector((state: any) => state.loggedInUserData);
  
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navigate = useNavigate()


  const handleProfileMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (selectedItem:string) => {
    setAnchorEl(null);
    if(selectedItem === "change_password") {
      navigate("/forget-password")
    }
    else if(selectedItem === "my_account") {
      navigate("/edit-account")
    }
    else if (selectedItem === "my_resumes") {
      navigate("/my-resumes")
    }
  };

  const handleLogout = () => {
    //removing token from localstorage
    removeToken()
    dispatch(logoutUser());
  }


  // if (!loggedInUserData || Object.keys(loggedInUserData).length === 0) {
  //   return null; // or a login link/component
  // }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={() => handleMenuClose("out_click")}
    >
      <MenuItem onClick={() => handleMenuClose("my_resumes")}>My Resumes</MenuItem>
      <MenuItem onClick={() => handleMenuClose("my_account")}>My Account</MenuItem>
      <MenuItem onClick={() => handleMenuClose("change_password")}>Change Password</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" noWrap>
            Welcome, {loggedInUserData?.firstName}!
          </Typography>
          <div style={{ flexGrow: 1 }} />
          <div>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </Box>
  );
}

export default Header;