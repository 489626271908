import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

interface CardDetails {
    details : {
    heading:string,
    subHeading:string,
    startDate:string,
    endDate:string,
    city:string,
    aboutText:string,
    },
    handleEditClick?:() => void;
    handleDeleteClick?:() => void;
}

const EducationCard:React.FC<CardDetails> = ({details, handleEditClick, handleDeleteClick}) => {
  return (
    <Card className="education-card-container" style={{ width: "100%" }}>
      <CardContent className='card-content'>
        <Box className="top-section">
        <Typography variant="h5" component="div" className="heading">
          {details?.heading}
        </Typography>
        <Typography variant="h6" className="sub-heading" gutterBottom>
          {details?.subHeading}
        </Typography>
        </Box>

        <Box className="middle-section">
            <Box className="duration">
            <Typography variant='body2'>
            {details?.startDate} to {details?.endDate}
            </Typography>
            <Typography variant='body2'>
            {details?.city}
            </Typography>
            </Box>
            <Box className="about-section">
                <Typography variant="body1" component="div" className="about-text ui-text-3-color">
                {details?.aboutText}
                </Typography>
            </Box>
        </Box>

      </CardContent>
      <CardActions className="bottom-section">
        <Box className="action-container">
            <Box className="edit-button" onClick={handleEditClick}><EditOutlinedIcon className="edit-icon"/></Box>
            <Box className="delete-button" onClick={handleDeleteClick}><DeleteOutlineOutlinedIcon className="delete-icon"/></Box>
        </Box>
      </CardActions>
    </Card>
  );
}

export default EducationCard;
