import './App.css';
import "./styles/style.scss";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import UserLandingPage from './UserLandingPage';
import ProcessResumeFeature from './ProcessResume/ProcessResumeFeature';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TemplatesList from './components/templates/TemplatesList';
import { Login, Register } from './components/auth';
import { Provider, useSelector } from 'react-redux';
import { createStore } from 'redux';
import reducer from './redux/reducer';
import LandingPage from './LandingPage/LandingPage';
import CreateAccount from './components/Accounts/CreateAccount';
import EditAccount from './components/Accounts/EditAccount';
import ForgetPassword from './components/Accounts/ForgetPassword';
import ATSCheckResult from './components/ATSChecker/ATSCheckResult';
import { ILoggedinUserData } from './components/utils';
import Header from './components/common/Header';
import { getToken } from './components/auth/storeToken';
import SavedResumes from './components/Accounts/SavedResume';

const theme = createTheme({
  typography: {
    fontFamily: [
      'inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  }
});


function AuthRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const accessToken = useSelector((state:any) => state.accessToken);
  const extractedData = useSelector((state:any) => state.extractedData);
  const loggedInUserData: ILoggedinUserData = useSelector((state: any) => state.loggedInUserData);

  const isAuthenticated = accessToken || (loggedInUserData && Object.keys(loggedInUserData).length > 0) || getToken();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  return children;
}


function App() {

const store = createStore(reducer);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
      <main>
        <div className='app-page-container'>
          <Router>
          <Routes>
            <Route path="landing-page" element={<LandingPage />} />
            <Route path="/" element={<AuthRoute><UserLandingPage /></AuthRoute>} />
            <Route path="login" element={<Login />} />
            <Route path="create-account" element={<CreateAccount />} />
            <Route path="register" element={<Register />} />
            <Route path="edit-account" element={<EditAccount />} />
            <Route path="forget-password" element={<ForgetPassword />} />
            <Route path="my-resumes" element={<AuthRoute><SavedResumes /></AuthRoute>} />
            <Route path="select-template" element={<AuthRoute><TemplatesList/></AuthRoute>}/>
            <Route path="ats-check-result" element={<AuthRoute><ATSCheckResult/></AuthRoute>}/>
            <Route path="process-resume/*" element={<AuthRoute><ProcessResumeFeature/></AuthRoute>}/>
          </Routes>
          </Router>
        </div>
      </main>
      </ThemeProvider>
    </Provider>
  );
}

export default App;