import React, { Suspense, useEffect, useState, useTransition } from 'react';
import Box  from "@mui/material/Box";
import { useSelector } from "react-redux";

const ProcessResumePreview = () => {
    const templateId = useSelector((state: any) => state.templateId);

    const [TemplateComponent, setTemplateComponent] = useState<React.LazyExoticComponent<React.ComponentType<any>> | null>(null);
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        startTransition(() => {
            const importTemplate = async () => {
                try {
                    const templateModule = await import(`../templates/Template${templateId || '1'}`);
                    if (templateModule.default) { // Ensure there's a default export
                        setTemplateComponent(() => templateModule.default);
                    } else {
                        console.error("Template component does not have a default export");
                        setTemplateComponent(null);
                    }
                } catch (error) {
                    console.error("Failed to load the template component", error);
                    setTemplateComponent(null);
                }
            };

            importTemplate();
        });
    }, [templateId]);

    return (
        <Box className="preview-template-container" style={{height: "100vh"}}>
            <Suspense fallback={<div>Loading template...</div>}>
                <Box className="preview-template" style={{ flex: 1, overflow: 'auto' }}>
                    {isPending ? <div>Loading new template...</div> : TemplateComponent ? <TemplateComponent /> : <div>Template not found</div>}
                </Box>
            </Suspense>
        </Box>
    )
}

export default ProcessResumePreview;