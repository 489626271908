import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as ProfilePhotoImage} from "../../assets/SVG/Upload-photo-big.svg";
import {ReactComponent as PersonalSiteIcon} from "../../assets/SVG/personal-site.svg";
import {ReactComponent as LinkedInIcon} from "../../assets/SVG/LinkedIn.svg";
import {ReactComponent as GithubIcon} from "../../assets/SVG/github.svg";
import {ReactComponent as SocialNetworkIcon} from "../../assets/SVG/social.svg";
import TextField from '@mui/material/TextField';
import { InputFieldCustomeStyle } from './ProcessResumeUtils';
import InputAdornment from '@mui/material/InputAdornment';
import { useSelector, useDispatch } from 'react-redux';
import { setExtractedData } from '../../redux/actions';
import defaultProfilePicture from "./../../assets/profile_picture.png";

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

const PersonalDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const extractedData = useSelector((state:any) => state.extractedData);

  if(!extractedData?.profile_Picture) {
    dispatch(setExtractedData({ ...extractedData, profile_Picture: defaultProfilePicture }));
  }

  const handleChange = (newValue: any, key: string) => {
    dispatch(setExtractedData({ ...extractedData, [key]: newValue }));
  };

  const handlePhoneChange = (index: number, newValue: string) => {
    const updatedPhones = [...extractedData.phone];
    updatedPhones[index] = newValue;
    handleChange(updatedPhones, 'phone')
  };

  const handleImageChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          dispatch(setExtractedData({ ...extractedData, profile_Picture: reader.result.toString() }));
        } else {
          console.error('Failed to read file');
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
      <Box className="personal-details-container">
          <Box className="personal-details-wrapper">
            <Box className="content">
              <Box className="main-heading">
                      <Box className="heading-box">
                          <Typography variant="h5" color="color.primary" className="heading">
                              Personal Details
                          </Typography>
                      </Box>
                      <Box className="page-indicator-box">
                          <Typography variant="h5" color="color.primary" className="page-indicator">
                              1 of 5
                          </Typography>
                      </Box>
              </Box>

              <Box className="profile-info">
              <Typography variant="h5" color="color.primary" className="heading">Profile Information</Typography>
              <Typography variant="h6" className="ui-text-3-color description">This will be shown on resume doc/pdf file you export</Typography> 
                  <Box className="icon-cont">
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="upload-photo"
                        onChange={handleImageChange}
                      />
                      <label htmlFor="upload-photo">
                        <Box className="icon-cont" style={{ cursor: 'pointer' }}>
                          {extractedData?.profile_Picture ? <img src={extractedData?.profile_Picture} alt="Uploaded" style={{maxWidth: "100px"}}/> : <ProfilePhotoImage />}
                        </Box>
                      </label>
                      <Box className="text-wrapper">
                      <Typography variant="body2" className="color-primary">Upload Photo</Typography>  
                      </Box>
                  </Box>
              </Box>

              <Box className="profile-details">
                  <Typography variant="h5" color="color.primary" className="heading">Profile Details</Typography>
                  <Typography variant="h6" className="ui-text-3-color description">This will be shown on resume doc/pdf file you export</Typography>
                  <Box className="input-box-container">
                    <Typography variant="body2" component="label" htmlFor="firstName" gutterBottom>
                      First Name*
                    </Typography>
                    <TextField
                      fullWidth
                      id="firstName"
                      value={extractedData?.name?.firstName}
                      onChange={(e) => handleChange({ ...extractedData?.name, firstName: e.target.value }, 'name')}
                      sx={InputFieldCustomeStyle}
                      placeholder="Ex: John"
                    />
                  </Box>
                  <Box className="input-box-container">
                    <Typography variant="body2" component="label" htmlFor="lastName" gutterBottom>
                      Last Name*
                    </Typography>
                    <TextField
                      fullWidth
                      id="lastName"
                      value={extractedData?.name?.lastName}
                      onChange={(e) => handleChange({ ...extractedData?.name, lastName: e.target.value }, 'name')}
                      sx={InputFieldCustomeStyle}
                      placeholder="Ex: Doe"
                    />
                  </Box>

                  <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="email" gutterBottom>
                          Email*
                      </Typography>
                      <TextField
                        fullWidth
                        id="email"
                        value={extractedData?.email}
                        onChange={(e) => handleChange(e.target.value, 'email')}
                        sx={InputFieldCustomeStyle}
                      />
                  </Box>

                  <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="designation" gutterBottom>
                          Designation*
                      </Typography>
                      <TextField
                        fullWidth
                        id="designation"
                        value={extractedData?.designation}
                        onChange={(e) => handleChange(e.target.value, 'designation')}
                        sx={InputFieldCustomeStyle}
                      />
                  </Box>

                  {extractedData?.phone?.map((phone: number, index: number) => (
                    <Box key={index} className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor={`phone-${index}`} gutterBottom>
                        Phone {index + 1}
                      </Typography>
                      <TextField
                        fullWidth
                        id={`phone-${index}`}
                        value={phone}
                        onChange={(e) => handlePhoneChange(index, e.target.value)}
                        sx={InputFieldCustomeStyle}
                      />
                    </Box>
                  ))}

                  <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="address" gutterBottom>
                          Address
                      </Typography>
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        id="address"
                        sx={InputFieldCustomeStyle}
                        value={extractedData?.location}
                        onChange={(e) => handleChange(e.target.value, 'location')}
                      />
                  </Box>
              </Box>

              <Box className="professional-summary">
                <Typography variant="h5" color="color.primary" className="heading">Professinal Summary</Typography>
                <Typography variant="h6" className="ui-text-3-color description">Write a short and energetic sentences to interest the reader!</Typography> 
                <Box className="input-box-container">
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        id="career-objective"
                        sx={InputFieldCustomeStyle}
                        value={extractedData?.careerObjective}
                        onChange={(e) => handleChange(e.target.value, 'careerObjective')}
                      />
                  </Box>
              </Box>

              <Box className="links">
                <Typography variant="h5" color="color.primary" className="heading">Links</Typography>
                <Typography variant="h6" className="ui-text-3-color description">LinkedIn, Github, Personal/Portfolio site, X(Twitter) etc.</Typography>

                <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="personalSite" gutterBottom>
                          Personal Site
                      </Typography>
                      <TextField
                          fullWidth
                          id="personalSite"
                          sx={InputFieldCustomeStyle}
                          placeholder="www.mysite.com"
                          value={extractedData?.socialLinks?.personal_Website !== "NA" ? extractedData?.socialLinks?.personal_Website : ""}
                          onChange={(e) => handleChange({ ...extractedData?.socialLinks, personal_Website: e.target.value }, 'socialLinks')}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonalSiteIcon />
                              </InputAdornment>
                            ),
                          }}
                          />
                  </Box>

                  <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="linkedIn" gutterBottom>
                          LinkedIn
                      </Typography>
                      <TextField
                          fullWidth
                          id="linkedIn"
                          sx={InputFieldCustomeStyle}
                          placeholder="www.linkedin.com/username"
                          value={extractedData?.socialLinks?.linkedIn_URL !== "NA" ? extractedData?.socialLinks?.linkedIn_URL : ""}
                          onChange={(e) => handleChange({ ...extractedData?.socialLinks, linkedIn_URL: e.target.value }, 'socialLinks')}
                          InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LinkedInIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                  </Box>

                  <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="gitHub" gutterBottom>
                          GitHub
                      </Typography>
                      <TextField
                          fullWidth
                          id="gitHub"
                          sx={InputFieldCustomeStyle}
                          placeholder="www.github.com/username"
                          value={extractedData?.socialLinks?.github_URL !== "NA" ? extractedData?.socialLinks?.github_URL : ""}
                          onChange={(e) => handleChange({ ...extractedData?.socialLinks, github_URL: e.target.value }, 'socialLinks')}
                          InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <GithubIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                  </Box>

                  <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="x" gutterBottom>
                          X
                      </Typography>
                      <TextField
                          fullWidth
                          id="x"
                          sx={InputFieldCustomeStyle}
                          placeholder="www.twitter.com/username"
                          value={extractedData?.socialLinks?.twitter_X_URL !== "NA" ? extractedData?.socialLinks?.twitter_X_URL : ""}
                          onChange={(e) => handleChange({ ...extractedData?.socialLinks, twitter_X_URL: e.target.value }, 'socialLinks')}
                          InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SocialNetworkIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                  </Box>

                  <Box className="add-link">
                    <Typography variant="body2" className="color-primary">Add Link</Typography>
                    <Typography variant="body2" className="color-primary">Save</Typography>
                  </Box>
              </Box>
            </Box>
          </Box>
      </Box>
  )
};

export default PersonalDetails;