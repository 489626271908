import { IResume } from '../components/utils';
import { LOGOUT_USER, SET_ACCESS_TOKEN, SET_COLOR_DATA, SET_EXTRACTED_DATA, SET_LOGGED_IN_USER_DATA, SET_SELECTED_TEMPLATE_ID } from './actionTypes';

interface StateProps {
  extractedData:IResume;
  color:string;
}

const initialState:StateProps = {
  extractedData: {
    name: {
      firstName: "",
      lastName: ""
    },
    profile_Picture: null,
    email: "",
    phone: [],
    location: "",
    socialLinks: {
      personal_Website: "",
      linkedIn_URL: "",
      github_URL: "",
      twitter_X_URL: null
    },
    careerObjective: "",
    designation: "",
    educationalDetails: [],
    workExperiences: [],
    projects: [],
    certifications: [],
    toolsTechnicalSkills: [],
    programmingLanguages: [],
    interests: []
  } as IResume,
  color:"default",
};

const dataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_EXTRACTED_DATA:
      return {
        ...state,
        extractedData: action.payload,
      };
    case SET_COLOR_DATA:
    return {
      ...state,
      color:action.payload,
    }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case SET_LOGGED_IN_USER_DATA:
      return {
        ...state,
        loggedInUserData: action.payload,
      };
    case SET_SELECTED_TEMPLATE_ID:
      return {
        ...state,
        templateId: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        loggedInUserData: null,
      };
    default:
      return state;
  }
};

export default dataReducer;