import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material"
import Header from "../common/Header";
import { getToken } from "../auth/storeToken";
import { useEffect, useState } from "react";
import { Templates } from "../utils";
import { useDispatch } from "react-redux";
import { setExtractedData } from "../../redux/actions";
import { useNavigate } from 'react-router-dom';

const SavedResumes = () => {
  
  const [resumes, setResumes] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResumes = async () => {
      try {
        const response = await fetch('https://testapi.tekno.ai/api/resumes', {
          method: 'GET',
          headers: {
            'accept': '*/*',
            'Authorization': 'Bearer ' + getToken(),
          }
        });

        if (response.ok) {
          let data = await response.json();
          data = data.map((resume: any) => JSON.parse(resume.resumeJson))
          setResumes(data);
        } else {
          console.error('Failed to fetch resumes');
        }
      } catch (error) {
        console.error('Error fetching resumes:', error);
      }
    };

    fetchResumes();
  }, []);

  const editTemplate = (resumeData: any) => {
    dispatch(setExtractedData(resumeData));
    navigate('/process-resume/personal-details');
  }


  return (
    <>
    <Header/>
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            <Typography component="h2" variant="h4" color="text.primary">
              My Resumes
            </Typography>
            {resumes.filter((resume:any) => resume.template_id ? true : false).map((resume:any, index) => (
              <Card key={index} sx={{ maxWidth: 345, mb: 2 }}>
                <CardActionArea>
                  {resume.template_id &&
                    <CardMedia
                      component="img"
                      image={Templates[resume.template_id].img}
                      alt={Templates[resume.template_id].name}
                      onClick={() => editTemplate(resume)}
                    />
                  }
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {resume.resume_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {resume.created_at}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary">
                    Download DOCX
                  </Button>
                  <Button size="small" color="primary">
                    Download PDF
                  </Button>
                  <Button size="small" color="primary">
                    Share
                  </Button>
                </CardActions>
              </Card>
            ))}
            <Card sx={{ maxWidth: 345, mb: 2 }}>
              <CardActionArea>
                
                <CardMedia
                  component="img"
                  image={""}
                  height={160}
                  alt={"Create New Resume"}
                  onClick={() => navigate("/")}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    +
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Create New Resume
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  Download DOCX
                </Button>
                <Button size="small" color="primary">
                  Download PDF
                </Button>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Container>
    </>
  )
}

export default SavedResumes;