import { Box, List } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { TiTick } from "react-icons/ti";
import { useDispatch } from "react-redux";
import { setColorData } from "../redux/actions";

const ColorPicker = () => {
    const [tempColor,setTempColor] = useState<string>("");
    const selectedColor = useRef<string|null>(null);
    const colorClick = useRef<boolean>(false);
    const [tickValue,setTickValue] = useState<string>("default");
    const dispatch = useDispatch();

    useEffect(()=> {
        if(tempColor) {
            dispatch(setColorData(tempColor))
        }
    },[tempColor,dispatch])

    const hanldeMouseEnter = (tempValue:string) => {
        selectedColor.current = tempColor;
        setTempColor(tempValue);
       }
     
       const handleMouseLeave = () => {
         if(colorClick?.current) {
           colorClick.current = false;
         }
         else {
           setTempColor(selectedColor.current || "")
         }
       }
     
       const handleColorClick = (colorValue:string) => {
         setTempColor(colorValue);
         colorClick.current = true;
         setTickValue(colorValue);
       }

       console.log(tempColor,"from temp color New")

       return (
        <Box className="template-color-picker-container">
          <Box className="template-color-picker-wrapper">
              <List className="template-color-picker-ul">
                <li className="list-item-color-0">
                   <button className="color-0" onClick={()=> handleColorClick("default")}
                    onMouseEnter={() =>hanldeMouseEnter("default")} 
                    onMouseLeave={handleMouseLeave}>
                    {tickValue === "default" &&
                     <TiTick color="silver" size={18} />}
                    </button>
                </li>
                <li className="list-item-color-1">
                 <button className="color-1"  onClick={()=> handleColorClick("tempColor1")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor1")} 
                    onMouseLeave={handleMouseLeave}>
                    {tickValue === "tempColor1" && <TiTick color="white" size={18} />}
                    </button>
                </li>
                <li className="list-item-color-2">
                  <button className="color-2" onClick={()=> handleColorClick("tempColor2")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor2")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor2" && <TiTick color="white" size={18} />}</button>
                </li>

                <li className="list-item-color-3">
                  <button className="color-3" onClick={()=> handleColorClick("tempColor3")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor3")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor3" && <TiTick color="white" size={18} />}</button>
                </li>
                <li className="list-item-color-4">
                  <button className="color-4" onClick={()=> handleColorClick("tempColor4")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor4")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor4" && <TiTick color="white" size={18} />}</button>
                </li>
                <li className="list-item-color-5">
                  <button className="color-5" onClick={()=> handleColorClick("tempColor5")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor5")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor5" && <TiTick color="white" size={18} />}</button>
                </li>

                <li className="list-item-color-6">
                  <button className="color-6" onClick={()=> handleColorClick("tempColor6")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor6")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor6" && <TiTick color="white" size={18} />}</button>
                </li>
                <li className="list-item-color-7">
                  <button className="color-7" onClick={()=> handleColorClick("tempColor7")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor7")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor7" && <TiTick color="white" size={18} />}</button>
                </li>
                <li className="list-item-color-8">
                  <button className="color-8" onClick={()=> handleColorClick("tempColor8")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor8")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor8" && <TiTick color="white" size={18} />}</button>
                </li>

                <li className="list-item-color-9">
                  <button className="color-9" onClick={()=> handleColorClick("tempColor9")}
                    onMouseEnter={() =>hanldeMouseEnter("tempColor9")} 
                    onMouseLeave={handleMouseLeave}>
                     {tickValue === "tempColor9" && <TiTick color="white" size={18} />}</button>
                </li>

              </List>
          </Box>
        </Box>
       )

}

export default ColorPicker;
