import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { InputFieldCustomeStyle, InputSelectCustomeStyle } from './ProcessResumeUtils';
import InputAdornment from '@mui/material/InputAdornment';
import {ReactComponent as PersonalSiteIcon} from "../../assets/SVG/personal-site.svg";
import {ReactComponent as DatePickerIcon} from "../../assets/SVG/date-picker.svg";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import styled from "styled-components";
import EducationCard from "./EducationCard";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";
import { useForm, Controller } from 'react-hook-form';

type FormDataType = {
  company: string;
  role: string;
  startDate: Date | null;
  endDate: Date | null;
  keyResponsibilities: string;
  projectDetails: string;
  toolsTechnologies: string;
};

const DEFAULT_FORM_DATA:FormDataType = {
  company: '',
  role: '',
  startDate: null,
  endDate: null,
  keyResponsibilities: '',
  projectDetails: '',
  toolsTechnologies: '',
};

const WorkExperience = () => {
  const { handleSubmit, control} = useForm<FormDataType>();
    const [editingIndex, setEditingIndex] = useState(null as null | number);
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);
    const [errors,setErrors] = useState({
      role: "",
      company:"",
      startDate:"",
      endDate:""
    });

    const dispatch = useDispatch();
    const extractedData = useSelector((state:any) => state.extractedData);

    const handleChange = (newValue: any, key: string) => {
      setFormData({
        ...formData,
        [key]: newValue,
      });
    };

    useEffect(() => {
      if (editingIndex !== null) {
        setFormData(extractedData.workExperiences[editingIndex]);
      }
      setErrors({
        role: "",
        company:"",
        startDate:"",
        endDate:""
      })
    }, [editingIndex]);

    useEffect(() => {
      dispatch(setExtractedData({
        ...extractedData,
        workExperiences: extractedData.workExperiences.map((item: any, index: number) => {
          if (index === editingIndex) {
            return formData;
          }
          return item;
        }),
      }));
    }, [formData]);

    const deleteWorkExperience = (index: number) => {
      dispatch(setExtractedData({
        ...extractedData,
        workExperiences: extractedData.workExperiences.filter((item: any, i: number) => i !== index),
      }));
    }

    const handleStartDateChange = (newValue: Date | null) => {
      if(newValue === null) return;
      const date = new Date(newValue);
     const formattedDate = date.toISOString().split('T')[0];

      handleChange(formattedDate, 'startDate');
      if (newValue && formData.endDate !== null && newValue > (formData.endDate as any)) {
        handleChange(null, 'endDate');
      }
    };
  
    const handleEndDateChange = (newValue: Date | null) => {
      if(newValue === null) return;
      const date = new Date(newValue);
      const formattedDate = date.toISOString().split('T')[0];
      handleChange(formattedDate, 'endDate');
    };

    const handleAddExperience = () => {
      setEditingIndex(extractedData.workExperiences.length);
      dispatch(setExtractedData({
        ...extractedData,
        workExperiences: [
          ...extractedData.workExperiences,
          DEFAULT_FORM_DATA
        ],
      }));
    }

    const handleSave = () => {
      if(!formData.role) {
        setErrors((prev)=>({
          ...prev,
          role:"Role is required"
        }))
      }
      if(!formData.company) {
        setErrors((prev)=>({
          ...prev,
          company:"Company name is required"
        }))
      }
      if(!formData.startDate){
        setErrors((prev)=>({
          ...prev,
          startDate:"Start Date is required"
        }))
      }
      if(!formData.endDate) {
        setErrors((prev)=>({
          ...prev,
          endDate:"End Date is required"
        }))
      }
      if(formData.role && formData.company && formData.startDate && formData.endDate) {
        setEditingIndex(null)
      }
    };

    return (
        <Box className="work-experience-container">
            <Box className="work-experience-wrapper">
              <Box className="content">
                <Box className="main-heading">
                  <Box className="heading-box">
                      <Typography variant="h5" color="color.primary" className="heading">
                          Work Experience
                      </Typography>
                  </Box>
                  <Box className="page-indicator-box">
                      <Typography variant="h5" color="color.primary" className="page-indicator">
                          2 of 5
                      </Typography>
                  </Box>
                </Box>


                {
                  editingIndex !== null ? (
                    <Box className="full-time" component="form" noValidate autoComplete="off">
                      <Box className="input-box-container">
                        <Typography variant="body2" component="label" htmlFor="role" gutterBottom>
                            Role
                        </Typography>
                            {/* <Controller
                              name="role"
                              control={control}
                              defaultValue=""
                              rules={{ required: 'Role is required' }}
                              render={({ field:{onChange,value,...rest} }) => (
                                <TextField
                                  {...rest}
                                  value={value}
                                  onChange={(e) => handleChange(e.target.value, 'role')}
                                  variant="outlined"
                                  placeholder="Ex: Software Engineer"
                                  sx={InputFieldCustomeStyle}
                                  fullWidth
                                  error={!!errors.role}
                                  helperText={errors.role ? errors.role.message : ''}
                                />
                              )}
                          /> */}

                        <TextField
                          fullWidth
                          id="role"
                          name="role"
                          value={formData?.role}
                          onChange={(e) => handleChange(e.target.value, 'role')}
                          sx={InputFieldCustomeStyle}
                          error={!!errors.role}
                          helperText={errors.role ? errors.role : ''}
                        />
                      </Box>
                      <Box className="input-box-container">
                        <Typography variant="body2" component="label" htmlFor="company" gutterBottom>Company</Typography>
                        {/* <Controller
                              name="company"
                              control={control}
                              defaultValue=""
                              rules={{ required: 'Company name is required' }}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  sx={InputFieldCustomeStyle}
                                  placeholder="Ex: Microsoft"
                                  variant="outlined"
                                  fullWidth
                                  error={!!errors.company}
                                  helperText={errors.company ? errors.company.message : ''}
                                />
                              )}
                          /> */}
                        <TextField
                          fullWidth
                          id="company"
                          name="company"
                          sx={InputFieldCustomeStyle}
                          placeholder="Ex: Software Engineer"
                          value={formData?.company}
                          onChange={(e) => handleChange(e.target.value, 'company')}
                          error={!!errors.company}
                          helperText={errors.company ? errors.company : ''}
                        />
                      </Box>

                      <Box className="date-picker-container">
                        <Box className="date-picker-wrapper">
                          <Typography variant="body2" component="label" htmlFor="startDate" gutterBottom>
                                Start Date
                          </Typography>
                          {/* <Controller
                            name="startDate"
                            control={control} // this comes from useForm()
                            defaultValue={null}
                            rules={{ required: 'startDate is required' }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  value={value}
                                  onChange={onChange}
                                  slots={{
                                    openPickerIcon: DatePickerIcon,
                                    textField: (params) => (
                                      <TextField
                                        {...params}
                                        id="startDate"
                                        name="startDate"
                                        sx={InputFieldCustomeStyle}
                                        error={!!errors.startDate}
                                        helperText={errors?.startDate ? errors.startDate.message : null}
                                      />
                                    ),
                                  }}
                                />
                              </LocalizationProvider>
                            )}
                          /> */}
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={formData.startDate as any}
                            onChange={handleStartDateChange}
                            slots={{
                                openPickerIcon: DatePickerIcon,
                                textField: (params) => (
                                  <TextField
                                    {...params}
                                    id="startDate"
                                    name="startDate"
                                    value={formData.startDate}
                                    sx={InputFieldCustomeStyle}
                                    error={!!errors.startDate}
                                    helperText={errors.startDate ? errors.startDate : ""}
                                  />
                                ),
                              }}
                              />
                        </LocalizationProvider>
                        </Box>

                        <Box className="date-picker-wrapper">
                            <Typography variant="body2" component="label" htmlFor="endDate" gutterBottom>
                                    End Date
                            </Typography>
                            {/* <Controller
                            name="endDate"
                            control={control} // this comes from useForm()
                            defaultValue={null}
                            rules={{ required: 'endDate is required' }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  value={value}
                                  onChange={onChange}
                                  slots={{
                                    openPickerIcon: DatePickerIcon,
                                    textField: (params) => (
                                      <TextField
                                        {...params}
                                        id="endDate"
                                        name="endDate"
                                        sx={InputFieldCustomeStyle}
                                        error={!!errors.endDate}
                                        helperText={errors.endDate ? errors.endDate.message : null}
                                      />
                                    ),
                                  }}
                                />
                              </LocalizationProvider>
                            )}
                          /> */}

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              value={formData?.endDate as any}
                              onChange={handleEndDateChange}
                              minDate={formData.startDate ? formData.startDate as any : undefined}
                              slots={{
                                openPickerIcon: DatePickerIcon,
                                textField: (params) => (
                                  <TextField
                                    {...params}
                                    id="endDate"
                                    name="endDate"
                                    value={formData.endDate}
                                    sx={InputFieldCustomeStyle}
                                    error={!!errors.endDate}
                                    helperText={errors.endDate ? errors.endDate : ""}
                                  />
                                ),
                              }}
                              /> 
                          </LocalizationProvider>
                        </Box>
                      </Box>

                      <Box className="input-box-container">
                      <Typography variant="body2" component="label" htmlFor="address" gutterBottom>
                          Key Responsibilities
                      </Typography>

                      {/* <Controller
                              name="keyResponsibilities"
                              
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  id="keyResponsibilities"
                                  sx={InputFieldCustomeStyle}
                                  placeholder="Ex: +91 123456789"
                                  variant="outlined"
                                  error={!!errors.keyResponsibilities}
                                  helperText={errors.keyResponsibilities ? errors.keyResponsibilities.message : ''}
                                />
                              )}
                          /> */}

                      <TextField
                          multiline
                          rows={4}
                          fullWidth
                          id="keyResponsibilities"
                          name="keyResponsibilities"
                          sx={InputFieldCustomeStyle}
                          placeholder="Ex: +91 123456789"
                          value={formData?.keyResponsibilities}
                          onChange={(e) => handleChange(e.target.value, 'keyResponsibilities')}
                          />
                      </Box>
                      
                      <Box className="input-box-container">
                        <Typography variant="body2" component="label" htmlFor="address" gutterBottom>
                          Project Details
                        </Typography>
                        {/* <Controller
                              name="projectDetails"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  multiline
                                  rows={4}
                                  fullWidth
                                  id="projectDetails"
                                  sx={InputFieldCustomeStyle}
                                  placeholder="Ex: +91 123456789"
                                  variant="outlined"
                                  error={!!errors.projectDetails}
                                  helperText={errors.projectDetails ? errors.projectDetails.message : ''}
                                />
                              )}
                          /> */}

                        <TextField
                          multiline
                          rows={4}
                          fullWidth
                          id="projectDetails"
                          name="projectDetails"
                          sx={InputFieldCustomeStyle}
                          placeholder="Ex: +91 123456789"
                          value={formData?.projectDetails}
                          onChange={(e) => handleChange(e.target.value, 'projectDetails')}
                          />
                      </Box>

                      <Box className="input-box-container">
                        <Button variant="contained" size="large" color="primary" onClick={handleSave}>Save</Button>
                      </Box>

                    </Box>
                  ):
                  extractedData.workExperiences && extractedData.workExperiences.map((workExperience: any, index: number) => (
                    <EducationCard
                      details={{
                        heading: workExperience.role,
                        subHeading: workExperience.company,
                        startDate: workExperience.startDate,
                        endDate: workExperience.endDate,
                        city: workExperience.location,
                        aboutText: workExperience.keyResponsibilities,
                      }}
                      handleEditClick={() => setEditingIndex(index)}
                      handleDeleteClick={() => deleteWorkExperience(index)}
                    />
                  ))
                }

                <Box className="add-work-exp">
                  <Typography variant="body2" className="color-primary" onClick={handleAddExperience}>Add Experience</Typography>
                </Box>
              </Box>
            </Box>

            
        </Box>
    )
}
export default WorkExperience;