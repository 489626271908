import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import {ReactComponent as CreateReusmeIcon } from "./assets/SVG/create-resume.svg";
import {ReactComponent as ChooseTemplateIcon} from "./assets/SVG/choose-template.svg";
import {ReactComponent as UploadReusmeIcon} from "./assets/SVG/upload-resume.svg";
import {ReactComponent as CreateWithAiIcon } from "./assets/SVG/create-with-ai.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setExtractedData } from './redux/actions';
import Loading from './components/Loading/Loading';
import { Alert, Snackbar } from '@mui/material';
import Header from './components/common/Header';

import AtsResumeCheckerPopup from './components/ATSChecker/AtsResumeCheckerPopup';
// import { getDocument } from 'pdfjs-dist';
// const PDFJS = window.pdfjsLib;

interface userOption {
    title:string;
    subTitle:string;
    Icon:React.ReactNode;
    svgClass:string;
    link:string;
    onClick?: () => void;
}

const UserLandingPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    const [isLoading, setIsLoading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [pdfBase64, setPdfBase64] = useState('');
    const [images, setImages] = useState([]);

    const [resumeActionType, setResumeActionType] = useState('');

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => {
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    // const convertToImage = async(file:File) => {
    //     if(!file) return;
    //     try {
    //         // Load the PDF document
    //           debugger;
    //           const uri = URL.createObjectURL(file);
    //           const pdfDoc = await pdfjsLib.getDocument({ data: uri }).promise;
    //           console.log(pdfDoc,"from middle")
              
              
    //         // Get the first page
    //           const page = await pdfDoc.getPage(1);

    //           // Set up the canvas for rendering
    //           const viewport = page.getViewport({ scale: 1 });
    //           const canvas = document.createElement('canvas');
    //           const context = canvas.getContext('2d');

    //           if (!context) {
    //               console.error('Failed to get canvas context');
    //               return;
    //             }
            

    //           canvas.width = viewport.width;
    //           canvas.height = viewport.height;

    //           // Render the page into the canvas
    //           await page.render({
    //           canvasContext: context,
    //           viewport: viewport,
    //           }).promise;

         

    //           // Convert the canvas to an image
    //           const imgDataUrl = canvas.toDataURL('image/png');
    //           return imgDataUrl; 

    //         const uri = URL.createObjectURL(file);
    //         var _PDF_DOC = await pdfjsLib.getDocument({ url: uri });
    //         const imagesList = [];
    // const canvas = document.createElement("canvas");
    // canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    // for (let i = 1; i <= pdf.numPages; i++) {
    //   var page = await _PDF_DOC.getPage(1);
    //   var viewport = page.getViewport({ scale: 1 });
    //   canvas.height = viewport.height;
    //   canvas.width = viewport.width;
    //   var render_context = {
    //     canvasContext: canvas.getContext("2d"),
    //     viewport: viewport
    //   };
    //   console.log("page lenght", pdf.numPages);
    //   setWidth(viewport.width);
    //   setHeight(viewport.height);
    //   await page.render(render_context).promise;
    //   let img = canvas.toDataURL("image/png");
    //   imagesList.push(img);
    // }
    //     }
    //     catch{
    //         throw new Error('Failed to convert pdf into image');
    //     }

    // }

    const handleConvert = async (base64string:string) => {
        try {
            const response = await fetch('https://be.tekno.ai/extract-face', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pdfBase64: base64string }),
            });
            const result = await response.json();
            if (response.ok) {
                return result.faceBase64;
            }
            return null;
        } catch (error) {
            console.error('Error converting PDF:', error);
        }
    };

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        if (event.target.files?.length) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            console.log(process.env.REACT_APP_API_URL,"from API")

            try {
                const response = await fetch(`https://testapi.tekno.ai/api/extract-text`, {
                    method: 'POST',
                    body: formData,
                });
                if (response.ok) {
                    const {textContent} = await response.json();
                    const requestData = { content: textContent } as any;
                    const endpointURL = resumeActionType === 'single-page-conversion' ? `https://be.tekno.ai/convert-resume-to-single-page` : `${process.env.REACT_APP_API_URL}/generate-content`;
                    const modelResponse = await fetch(endpointURL, {
                        method: 'POST',
                        body: JSON.stringify(requestData),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    const resumeObj = await modelResponse.json();

                    /**
                     * Converting pdf to image
                    */

                    const reader:any = new FileReader();
                    reader.onloadend = () => {
                        const base64string = reader.result.split(',')[1];
                        const extractedFace = handleConvert(base64string);
                        debugger
                        if (extractedFace) {
                            resumeObj.profile_Picture = extractedFace;
                        }
                    };
                    reader.readAsDataURL(file);
                    console.log("resumeObj", resumeObj);
                    // const imgDataUrl = convertToImage(file);
                    dispatch(setExtractedData(resumeObj));
                    navigate('/process-resume/personal-details');
                } else {
                    console.error('Upload failed');
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const triggerFileInputClick = () => {
        fileInputRef.current?.click();
    };

    const userOptions: userOption[] = [
        {
            title:"Create New Resume",
            subTitle:"build your unique one by yourself!",
            Icon:<CreateReusmeIcon/>,
            svgClass:"svg-fill-secondary-hover-primary cursor-pointer",
            link:"/process-resume/personal-details",
        },
        // {
        //     title:"Choose Template",
        //     subTitle:"select from 100+ professional resumes",
        //     Icon:<ChooseTemplateIcon/>,
        //     svgClass:"svg-fill-stroke-secondary-hover-primary cursor-pointer",
        //     link:"/select-template",
        // },
        {
            title:"Upload Resume",
            subTitle:"fastrack your resume creation",
            Icon:<UploadReusmeIcon/>,
            svgClass:"svg-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: triggerFileInputClick
        },
        {
            title:"ATS Resume Checker",
            subTitle:"Review, score & improve your resume",
            Icon:<ChooseTemplateIcon/>,
            svgClass:"svg-fill-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: handleOpenPopup
        },
        {
            title:"Convert to Single Page Resume",
            subTitle:"Upload your multi-page resume",
            Icon:<CreateWithAiIcon/>,
            svgClass:"svg-stroke-secondary-hover-primary cursor-pointer",
            link:"/",
            onClick: () => {
                setResumeActionType('single-page-conversion');
                triggerFileInputClick();
            }
        },
    ];

    const sendBase64Image = async (base64Image: string) => {
        // Convert base64 string to Blob
        const base64ToBlob = (base64: string, contentType: string) => {
            const byteCharacters = atob(base64);
            const byteArrays = [];
    
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
    
            return new Blob(byteArrays, { type: contentType });
        };
    
        const contentType = 'image/png'; // Change this to the appropriate MIME type
        const blob = base64ToBlob(base64Image, contentType);
    
        // Create FormData and append the Blob
        const formData = new FormData();
        formData.append('file', blob, 'image.png'); // 'image.png' is the filename
    
        // Send the request
        try {
            const response = await fetch('https://testapi.tekno.ai/api/detect-profile', {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': '*/*',
                },
            });
    
            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (location.state?.isLoginSuccessful) {
            setOpenSnackbar(true);
        }
    }, [location]);

    return (
        isLoading ?
        <Loading/> :
        <>
            <Header />
            <Box
            className="flex-column-center user-landing-page-container"
            >
                <Snackbar open={openSnackbar} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        Welcome back! You've successfully logged in.
                    </Alert>
                </Snackbar>
                <Box className="user-landing-page-wrapper">
                <Box className="top-heading-container">
                <Box>
                <Typography variant="h4" className="heading">How do you wanna start?</Typography>
                </Box>
                <Box>
                <Typography variant="h6" color="text.secondary" className="sub-heding">we can begin in a few ways: pick the one that works best for you</Typography>
                </Box>
                </Box>
                <Box className="content">
                    <Grid container spacing={4} className="card-container"  
                    >
                    {userOptions.map((option:userOption) => (
                        <Grid item xs={6} onClick={() => option.onClick ? option.onClick() : navigate(`${option.link}`)} >
                            <Card
                            sx={{
                                border:"1.5px solid #fff",
                                '&:hover': {
                                border:"1.5px solid #5594ff",
                                    },}}
                                    className={option.svgClass}
                            >
                            <CardMedia>
                            <Box
                            pt={4}
                            className="flex-row-center"
                            >
                            {option.Icon} 
                            </Box>
                            </CardMedia>
                            <CardContent sx={{display:"flex",
                                flexDirection:"column",
                                justifyContent:"center",
                                alignItems:"center"}}>
                                <Typography variant="h6" component="div">
                                {option.title}
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                {option.subTitle}
                                </Typography>
                            </CardContent>
                            </Card>
                        </Grid>
                    ))}
                    </Grid>
                    <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                    />
                </Box>
                <Box className="flex-row-center bottom-text-container" >
                    <Typography variant="h6" color="text.secondary">Need Any Help?<span className="color-primary"> email us.</span></Typography>
                </Box> 
                </Box> 
            </Box>
            <AtsResumeCheckerPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
            />
        </>
    )
}

export default UserLandingPage;