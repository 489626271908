import { Box, Button, List, ListItem } from '@mui/material';
import {ReactComponent as TeknoLogo} from "../assets/SVG/tekno-log.svg"

const optionList = [
    {
        name:"Home",
        link:"/",
    },
    {
        name:"Profession",
        link:"/",
    },
    {
        name:"Industry",
        link:"/",
    },
    {
        name:"Pricing",
        link:"/",
    }
]

const LandingPageHeader = () => {
    return (
        <Box className="landing-page-header-container">
                <Box className="logo">
                    <TeknoLogo/>
                </Box>
                <Box className="options">
                    <Box className="options-wrapper">
                    <List className='list'>
                            {optionList?.map((ele) => (<ListItem className='list-item'>{ele?.name}</ListItem>))}
                        </List>
                    </Box>
                </Box>
                <Box className="login-options">
                    <Box className="login-options-wrapper">
                    <List className='list'>
                        <ListItem className='list-item'>Login</ListItem>
                        <ListItem className='list-item'><Button variant="contained" className="make-resume-button">Make Resume</Button></ListItem>
                    </List>
                    </Box>
                </Box>
        </Box>
    )
};

export default LandingPageHeader;