import  LinearProgress  from '@mui/material/LinearProgress';
import  Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {ReactComponent as ResumeScoreMarkIcon} from "../assets/SVG/resume-score-mark.svg";
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 1,
  background:"#cbd5e1",
}));

const calculateResumeScore = (resumeDetails: any) => {
  let score = 0;
  // Basic info
  if (resumeDetails?.name && resumeDetails?.name?.firstName?.trim() && resumeDetails?.name?.lastName?.trim()) score += 10;
  if (resumeDetails?.profile_Picture) score += 5; // Renamed from profilePicture to profile_Picture
  if (resumeDetails?.email && resumeDetails?.email?.trim()) score += 5;
  if (resumeDetails?.phone && resumeDetails?.phone.length) score += 5;
  if (resumeDetails?.location && resumeDetails?.location?.trim()) score += 5; // Re-added location

  // Social links and career objective
  if (resumeDetails?.socialLinks && resumeDetails?.socialLinks.linkedInURL && resumeDetails?.socialLinks.linkedInURL?.trim()) score += 5; // Re-added socialLinks and linkedInURL
  if (resumeDetails?.careerObjective && resumeDetails?.careerObjective?.trim()) score += 10;

  // Detailed sections
  if (resumeDetails?.educationalDetails && resumeDetails?.educationalDetails.length) score += 15;
  if (resumeDetails?.workExperiences && resumeDetails?.workExperiences.length) score += 20;
  if (resumeDetails?.projects && resumeDetails?.projects.length) score += 20; // Re-added projects
  if (resumeDetails?.certifications && resumeDetails?.certifications?.length) score += 10; // Re-added certifications
  if (resumeDetails?.toolsTechnicalSkills && resumeDetails?.toolsTechnicalSkills?.length) score += 10;
  if (resumeDetails?.programmingLanguages && resumeDetails?.programmingLanguages?.length) score += 10; // Re-added programmingLanguages
  if (resumeDetails?.interests && resumeDetails?.interests?.length) score += 5; // Re-added interests

  // Normalize or scale the score if necessary
  const maxScore = 140; // Adjusted maxScore back to 120 to account for all sections
  let normalizedScore = (score * 100) / maxScore;

  // Ensure the score is within 0 to 100
  normalizedScore = Math.max(0, Math.min(100, normalizedScore));

  return normalizedScore;
}

const ResumeScoreProgressBar = () => {

  const resumeDetails = useSelector((state:any) => state.extractedData);

  const score = useMemo(() => calculateResumeScore(resumeDetails), [resumeDetails]);
  

  return (
    <Box className="resume-score-progress-bar">
      <Box className="content">
        <Box className="left">
        <Box className="progress-chip">
            <Typography variant="body1" className="color-primary">{score.toFixed(0)}%</Typography>
          </Box>
          <Box>
            <Typography variant="body1">Resume Score</Typography>
          </Box>
        </Box>
        <Box className="right">
          <Box className="icon-cont">
           <ResumeScoreMarkIcon/>
          </Box>
        </Box>
      </Box>
      <Box>
        <BorderLinearProgress variant="determinate" value={score} />
      </Box>
    </Box>
  )
}

export default ResumeScoreProgressBar;