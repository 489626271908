export interface skillOption {
    label: string;
    value: string;
    inputValue?: string;
}

export const skillsData:skillOption[] = 
[
    { "label": "JavaScript", "value": "JavaScript" },
    { "label": "Python", "value": "Python" },
    { "label": "HTML", "value": "HTML" },
    { "label": "CSS", "value": "CSS" },
    { "label": "React", "value": "React" },
    { "label": "Node.js", "value": "Node.js" },
    { "label": "Express", "value": "Express" },
    { "label": "MongoDB", "value": "MongoDB" },
    { "label": "SQL", "value": "SQL" },
    { "label": "Git", "value": "Git" },
    { "label": "Docker", "value": "Docker" },
    { "label": "Kubernetes", "value": "Kubernetes" },
    { "label": "AWS", "value": "AWS" },
    { "label": "Azure", "value": "Azure" },
    { "label": "Google Cloud Platform", "value": "Google Cloud Platform" },
    { "label": "Linux", "value": "Linux" },
    { "label": "CI/CD", "value": "CI/CD" },
    { "label": "Jenkins", "value": "Jenkins" },
    { "label": "Agile Methodologies", "value": "Agile Methodologies" },
    { "label": "Scrum", "value": "Scrum" },
    { "label": "Kanban", "value": "Kanban" },
    { "label": "JIRA", "value": "JIRA" },
    { "label": "RESTful APIs", "value": "RESTful APIs" },
    { "label": "GraphQL", "value": "GraphQL" },
    { "label": "TypeScript", "value": "TypeScript" },
    { "label": "Webpack", "value": "Webpack" },
    { "label": "Babel", "value": "Babel" },
    { "label": "Sass", "value": "Sass" },
    { "label": "LESS", "value": "LESS" },
    { "label": "Unit Testing", "value": "Unit Testing" },
    { "label": "Integration Testing", "value": "Integration Testing" },
    { "label": "Automation Testing", "value": "Automation Testing" },
    { "label": "Jest", "value": "Jest" },
    { "label": "Mocha", "value": "Mocha" },
    { "label": "Chai", "value": "Chai" },
    { "label": "Cypress", "value": "Cypress" },
    { "label": "Postman", "value": "Postman" },
    { "label": "Swagger", "value": "Swagger" },
    { "label": "Redux", "value": "Redux" },
    { "label": "MobX", "value": "MobX" },
    { "label": "Vue.js", "value": "Vue.js" },
    { "label": "Angular", "value": "Angular" },
    { "label": "Bootstrap", "value": "Bootstrap" },
    { "label": "Material-UI", "value": "Material-UI" },
    { "label": "Tailwind CSS", "value": "Tailwind CSS" },
    { "label": "npm", "value": "npm" },
    { "label": "yarn", "value": "yarn" },
    { "label": "ESLint", "value": "ESLint" },
    { "label": "Prettier", "value": "Prettier" },
    { "label": "Redux Toolkit", "value": "Redux Toolkit" },
    { "label": "RxJS", "value": "RxJS" },
    { "label": "Storybook", "value": "Storybook" },
    { "label": "Next.js", "value": "Next.js" },
    { "label": "Nuxt.js", "value": "Nuxt.js" },
    { "label": "ASP.NET", "value": "ASP.NET" },
    { "label": "C#", "value": "C#" },
    { "label": "Java", "value": "Java" },
    { "label": "Spring Boot", "value": "Spring Boot" },
    { "label": "Hibernate", "value": "Hibernate" },
    { "label": "Ruby on Rails", "value": "Ruby on Rails" },
    { "label": "Django", "value": "Django" },
    { "label": "Flask", "value": "Flask" },
    { "label": "Laravel", "value": "Laravel" },
    { "label": "PHP", "value": "PHP" },
    { "label": "WordPress", "value": "WordPress" },
    { "label": "Drupal", "value": "Drupal" },
    { "label": "Joomla", "value": "Joomla" },
    { "label": "Magento", "value": "Magento" },
    { "label": "Shopify", "value": "Shopify" },
    { "label": "Salesforce", "value": "Salesforce" },
    { "label": "Tableau", "value": "Tableau" },
    { "label": "Power BI", "value": "Power BI" },
    { "label": "Machine Learning", "value": "Machine Learning" },
    { "label": "Deep Learning", "value": "Deep Learning" },
    { "label": "Data Science", "value": "Data Science" },
    { "label": "Big Data", "value": "Big Data" },
    { "label": "Hadoop", "value": "Hadoop" },
    { "label": "Spark", "value": "Spark" },
    { "label": "Kafka", "value": "Kafka" },
    { "label": "Terraform", "value": "Terraform" },
    { "label": "Ansible", "value": "Ansible" },
    { "label": "Chef", "value": "Chef" },
    { "label": "Puppet", "value": "Puppet" },
    { "label": "Firebase", "value": "Firebase" },
    { "label": "SQLite", "value": "SQLite" },
    { "label": "Oracle", "value": "Oracle" },
    { "label": "PostgreSQL", "value": "PostgreSQL" },
    { "label": "MariaDB", "value": "MariaDB" },
    { "label": "Redis", "value": "Redis" },
    { "label": "Elasticsearch", "value": "Elasticsearch" },
    { "label": "RabbitMQ", "value": "RabbitMQ" },
    { "label": "Microservices", "value": "Microservices" },
    { "label": "Serverless", "value": "Serverless" },
    { "label": "OAuth", "value": "OAuth" },
    { "label": "JWT", "value": "JWT" },
    { "label": "WebSockets", "value": "WebSockets" },
    { "label": "MVC", "value": "MVC" },
    { "label": "MVVM", "value": "MVVM" },
    { "label": "TDD", "value": "TDD" },
    { "label": "BDD", "value": "BDD" },
    { "label": "DDD", "value": "DDD" }
]

export const hobbiesData:skillOption[] = 
[
    { "label": "Reading", "value": "Reading" },
    { "label": "Writing", "value": "Writing" },
    { "label": "Traveling", "value": "Traveling" },
    { "label": "Cooking", "value": "Cooking" },
    { "label": "Gardening", "value": "Gardening" },
    { "label": "Photography", "value": "Photography" },
    { "label": "Painting", "value": "Painting" },
    { "label": "Drawing", "value": "Drawing" },
    { "label": "Music", "value": "Music" },
    { "label": "Dancing", "value": "Dancing" },
    { "label": "Hiking", "value": "Hiking" },
    { "label": "Fishing", "value": "Fishing" },
    { "label": "Camping", "value": "Camping" },
    { "label": "Cycling", "value": "Cycling" },
    { "label": "Running", "value": "Running" },
    { "label": "Swimming", "value": "Swimming" },
    { "label": "Yoga", "value": "Yoga" },
    { "label": "Meditation", "value": "Meditation" },
    { "label": "Gaming", "value": "Gaming" },
    { "label": "Board Games", "value": "Board Games" },
    { "label": "Puzzles", "value": "Puzzles" },
    { "label": "Knitting", "value": "Knitting" },
    { "label": "Crocheting", "value": "Crocheting" },
    { "label": "Sewing", "value": "Sewing" },
    { "label": "Crafting", "value": "Crafting" },
    { "label": "Woodworking", "value": "Woodworking" },
    { "label": "DIY Projects", "value": "DIY Projects" },
    { "label": "Model Building", "value": "Model Building" },
    { "label": "Bird Watching", "value": "Bird Watching" },
    { "label": "Stargazing", "value": "Stargazing" },
    { "label": "Volunteering", "value": "Volunteering" },
    { "label": "Blogging", "value": "Blogging" },
    { "label": "Vlogging", "value": "Vlogging" },
    { "label": "Podcasting", "value": "Podcasting" },
    { "label": "Collecting", "value": "Collecting" },
    { "label": "Antiquing", "value": "Antiquing" },
    { "label": "Genealogy", "value": "Genealogy" },
    { "label": "Astronomy", "value": "Astronomy" },
    { "label": "Science Experiments", "value": "Science Experiments" },
    { "label": "Homebrewing", "value": "Homebrewing" },
    { "label": "Wine Tasting", "value": "Wine Tasting" },
    { "label": "Cooking Classes", "value": "Cooking Classes" },
    { "label": "Language Learning", "value": "Language Learning" },
    { "label": "Martial Arts", "value": "Martial Arts" },
    { "label": "Rock Climbing", "value": "Rock Climbing" },
    { "label": "Skydiving", "value": "Skydiving" },
    { "label": "Scuba Diving", "value": "Scuba Diving" },
    { "label": "Surfing", "value": "Surfing" },
    { "label": "Skiing", "value": "Skiing" },
    { "label": "Snowboarding", "value": "Snowboarding" },
    { "label": "Tennis", "value": "Tennis" },
    { "label": "Golf", "value": "Golf" },
    { "label": "Basketball", "value": "Basketball" },
    { "label": "Soccer", "value": "Soccer" },
    { "label": "Baseball", "value": "Baseball" },
    { "label": "Football", "value": "Football" },
    { "label": "Badminton", "value": "Badminton" },
    { "label": "Table Tennis", "value": "Table Tennis" },
    { "label": "Fencing", "value": "Fencing" },
    { "label": "Archery", "value": "Archery" },
    { "label": "Horseback Riding", "value": "Horseback Riding" }
]

export const languagesData:skillOption[] =
[
    { "label": "English", "value": "English" },
    { "label": "Spanish", "value": "Spanish" },
    { "label": "Mandarin Chinese", "value": "Mandarin Chinese" },
    { "label": "Hindi", "value": "Hindi" },
    { "label": "French", "value": "French" },
    { "label": "Arabic", "value": "Arabic" },
    { "label": "Bengali", "value": "Bengali" },
    { "label": "Russian", "value": "Russian" },
    { "label": "Portuguese", "value": "Portuguese" },
    { "label": "Indonesian", "value": "Indonesian" },
    { "label": "German", "value": "German" },
    { "label": "Japanese", "value": "Japanese" },
    { "label": "Korean", "value": "Korean" },
    { "label": "Italian", "value": "Italian" },
    { "label": "Turkish", "value": "Turkish" },
    { "label": "Polish", "value": "Polish" },
    { "label": "Dutch", "value": "Dutch" },
    { "label": "Greek", "value": "Greek" },
    { "label": "Swedish", "value": "Swedish" },
    { "label": "Norwegian", "value": "Norwegian" },
    { "label": "Danish", "value": "Danish" },
    { "label": "Finnish", "value": "Finnish" },
    { "label": "Hungarian", "value": "Hungarian" },
    { "label": "Czech", "value": "Czech" },
    { "label": "Slovak", "value": "Slovak" },
    { "label": "Romanian", "value": "Romanian" },
    { "label": "Bulgarian", "value": "Bulgarian" },
    { "label": "Croatian", "value": "Croatian" },
    { "label": "Serbian", "value": "Serbian" },
    { "label": "Ukrainian", "value": "Ukrainian" },
    { "label": "Hebrew", "value": "Hebrew" },
    { "label": "Vietnamese", "value": "Vietnamese" },
    { "label": "Thai", "value": "Thai" },
    { "label": "Malay", "value": "Malay" },
    { "label": "Filipino", "value": "Filipino" },
    { "label": "Swahili", "value": "Swahili" },
    { "label": "Zulu", "value": "Zulu" },
    { "label": "Xhosa", "value": "Xhosa" },
    { "label": "Afrikaans", "value": "Afrikaans" },
    { "label": "Persian", "value": "Persian" },
    { "label": "Pashto", "value": "Pashto" },
    { "label": "Urdu", "value": "Urdu" },
    { "label": "Punjabi", "value": "Punjabi" },
    { "label": "Tamil", "value": "Tamil" },
    { "label": "Telugu", "value": "Telugu" },
    { "label": "Marathi", "value": "Marathi" },
    { "label": "Gujarati", "value": "Gujarati" },
    { "label": "Kannada", "value": "Kannada" },
    { "label": "Malayalam", "value": "Malayalam" },
    { "label": "Sinhalese", "value": "Sinhalese" },
    { "label": "Burmese", "value": "Burmese" },
    { "label": "Khmer", "value": "Khmer" },
    { "label": "Lao", "value": "Lao" },
    { "label": "Mongolian", "value": "Mongolian" },
    { "label": "Nepali", "value": "Nepali" },
    { "label": "Tibetan", "value": "Tibetan" },
    { "label": "Haitian Creole", "value": "Haitian Creole" }
]



