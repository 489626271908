import Button from "@mui/material/Button";
import  Box  from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { InputFieldCustomeStyle } from "./ProcessResumeUtils";
import { ChangeEvent, useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import { hobbiesData, languagesData, skillOption, skillsData } from "../../Data/skillsData";
import Autocomplete,{createFilterOptions} from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";

const filter = createFilterOptions<skillOption>();

const Skills = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [skills, setSkills] = useState<skillOption[]>([]);
    const [hobbies, setHobbies] = useState<string[]>([]);
    const [languages, setLanguages] = useState<string[]>([]);

    const handleSkillChange = (e:any,newValue:any[]) => {
        const inputValue = newValue?.[newValue?.length -1]?.inputValue;
        if(inputValue) {
            newValue[newValue.length -1] =  {label:inputValue,value:inputValue}
        }
        console.log(newValue);
        setSkills(newValue);
    }

    const handleHobbyChange = (e:any,newValue:any) => {
        const inputValue = newValue?.[newValue?.length -1]?.inputValue;
        if(inputValue) {
            newValue[newValue.length -1] =  {label:inputValue,value:inputValue}
        }
        setHobbies(newValue);
    }

    const handleLanguageChange = (e:any,newValue:any) => {
        const inputValue = newValue?.[newValue?.length -1]?.inputValue;
        if(inputValue) {
            newValue[newValue.length -1] =  {label:inputValue,value:inputValue}
        }
        setLanguages(newValue);
    }

    return (
        <Box className="skills-container">
            <Box className="skills-wrapper">
                <Box className="content">
                <Box className="main-heading">
                        <Box className="heading-box">
                            <Typography variant="h5" color="color.primary" className="heading">
                                Skills, Languages & Hobbies
                            </Typography>
                        </Box>
                        <Box className="page-indicator-box">
                            <Typography variant="h5" color="color.primary" className="page-indicator">
                                4 of 5
                            </Typography>
                        </Box>
                </Box>

                <Box className="skills-section">
                <Typography variant="h5" color="color.primary" className="heading">Skills</Typography>
                    <Typography variant="h6" className="ui-text-3-color description">This will be shown on resume doc/pdf file you export</Typography>
                    <Box className="input-box-container">
                          <Autocomplete
                            multiple
                            id="skills"
                            fullWidth
                            options={skillsData}
                            value={skills}
                            onChange={handleSkillChange}
                            getOptionLabel={(option:any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return `Add ${option.inputValue}`;
                                }
                                // Regular option
                                return option.label;
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                        
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label
                                );
                                if (inputValue !== "" && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add ${inputValue}"`,
                                    value: `Add ${inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                            freeSolo
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.Comunication"
                                sx={InputFieldCustomeStyle}
                            />
                            )}
                            />
                    </Box>
                </Box>

                <Box className="skills-section">
                <Typography variant="h5" color="color.primary" className="heading">
                    Hobbies
                </Typography>
                    <Typography variant="h6" className="ui-text-3-color description">This will be shown on resume doc/pdf file you export</Typography>
                    <Box className="input-box-container">
                             <Autocomplete
                            multiple
                            id="hobbies"
                            fullWidth
                            options={hobbiesData}
                            value={hobbies}
                            onChange={handleHobbyChange}
                            getOptionLabel={(option:any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return `Add ${option.inputValue}`;
                                }
                                // Regular option
                                return option.label;
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                        
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label
                                );
                                if (inputValue !== "" && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add ${inputValue}"`,
                                    value: `Add ${inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                            freeSolo
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.Football"
                                sx={InputFieldCustomeStyle}
                            />
                            )}
                            />
                    </Box>
                </Box>

                <Box className="skills-section">
                <Typography variant="h5" color="color.primary" className="heading">Languages</Typography>
                    <Typography variant="h6" className="ui-text-3-color description">This will be shown on resume doc/pdf file you export</Typography>
                    <Box className="input-box-container">
                        {/* May be required in future version*/}
                        {/* <TextField
                            fullWidth
                            id="fullName"
                            sx={InputFieldCustomeStyle}
                            placeholder="Ex: English"
                            onChange={handleLanguageChange}
                            value={language}
                            />
                          <Button variant="contained" size="small" color="primary"
                             onClick={addLanguage}
                            >
                             Add
                            </Button> */}
                             <Autocomplete
                            multiple
                            id="hobbies"
                            fullWidth
                            options={languagesData}
                            value={languages}
                            onChange={handleLanguageChange}
                            getOptionLabel={(option:any) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return `Add ${option.inputValue}`;
                                }
                                // Regular option
                                return option.label;
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                        
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options.some(
                                  (option) => inputValue === option.label
                                );
                                if (inputValue !== "" && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    label: `Add ${inputValue}"`,
                                    value: `Add ${inputValue}"`,
                                  });
                                }
                                return filtered;
                              }}
                            freeSolo
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Ex.English"
                                sx={InputFieldCustomeStyle}
                            />
                            )}
                            />
                    </Box>

                    {/* May be required in future version*/}
                    {/* <Box className="skills-list">
                        {languages?.map((language) => (
                             <Chip
                             label={language}
                             variant="outlined"
                             onDelete={() => handleDeleteLanguage(language)}
                            sx={{
                                    borderRadius:"5px",
                                    backgroundColor:"#f1f5f9",
                                    border:"1px solid lightgray",
                                    '& .MuiChip-label': {
                                      display: 'block',
                                      whiteSpace: 'normal',
                                    },
                                }}
                             
                           />
                        ))}
                    </Box> */}
                </Box>
                </Box>
            </Box>

            
        </Box>
    )
}

export default Skills;