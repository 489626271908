import { Box, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const ProcessResumeFooter = () => {
    const navigate = useNavigate();
    const url = useParams();
    const params = url["*"];
    let footerObj:{[key:string]:{prev:string|null,next:string|null}} = {};
    if (params) {
         footerObj = {
            "personal-details":{
                prev:null,
                next:"/process-resume/work-experience"
            },
            "work-experience" :{
                prev:"/process-resume/personal-details",
                next:"/process-resume/education",
            },
            "education":{
                prev:"/process-resume/work-experience",
                next:"/process-resume/skills",
            },
            "skills":{
                prev:"/process-resume/education",
                next:null,
            }

        }
    }
    console.log(url["*"],'params');
    return (
        
             <Box className="footer">
          {params && footerObj[params] && <Box className="footer-wrapper">
              <Button variant="outlined" size="large" 
              className="tekno-grey-outline-black-button"
              onClick={()=> {
                if(footerObj[params]?.prev && typeof footerObj[params]?.prev === "string") {
                    //@ts-ignore
                  return  navigate(footerObj[params]?.prev)
                }
                }
              }
              >
                Cancel
              </Button>
              <Button variant="contained" size="large" color="primary"
                onClick={()=> {
                    if(footerObj[params]?.next && typeof footerObj[params]?.next === "string") {
                        //@ts-ignore
                      return  navigate(footerObj[params]?.next)
                    }
                    }}
              >
                Next
              </Button>
          </Box>}
          </Box>
        
    )
}
export default ProcessResumeFooter;