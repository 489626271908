import React from 'react';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemText, Divider, Grid, LinearProgress, Chip, Container, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from '../common/Header';
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles({
    root: {
        padding: '20px',
        overflowY: 'auto', // Enable vertical scrolling
        maxHeight: 'calc(100vh - 64px)', // Adjust height to fit within the viewport
    },
    card: {
        marginBottom: '20px',
        borderRadius: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    sectionTitle: {
        marginBottom: '10px',
        fontWeight: 'bold',
        color: '#3f51b5',
    },
    progress: {
        marginBottom: '10px',
    },
    listItem: {
        paddingLeft: '0px',
    },
    listItemText: {
        fontSize: '1rem',
    },
    divider: {
        margin: '20px 0',
    },
    chip: {
        margin: '5px',
    },
    backButton: {
      marginBottom: '20px',
  },
});

const ATSCheckResult = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const data = {
        summary: {
            "ATS Compatibility Score": "75%",
            "Keyword Metrics": {
                "Keyword Density": "3.5%",
                "Keyword Relevance": "8/10",
                "Top Keywords": [
                    "Power BI",
                    "Data Analysis",
                    "Dashboards",
                    "SQL",
                    "Reporting",
                    "Visualization",
                    "Data Fetching",
                    "Requirements Gathering",
                    "Automation",
                    "Process Improvement"
                ]
            },
            "Resume Structure and Format": {
                "Clear Headings": "90%",
                "Bullet Point Usage": "80%",
                "White Space Usage": "8/10"
            },
            "Content and Relevance": {
                "Work Experience Relevance": "9/10",
                "Skill Match": "85%",
                "Education Relevance": "7/10"
            },
            "Readability and Clarity": {
                "Readability Score": "65",
                "Sentence Complexity": "16 words",
                "Jargon and Acronyms": "15%"
            },
            "Actionable Tips and Suggestions": {
                "Improvement Suggestions": [
                    "Quantify achievements in bullet points with specific numbers and results.",
                    "Use action verbs to start bullet points.",
                    "Add more keywords related to the specific job description.",
                    "Tailor the resume to each specific job application."
                ],
                "Keyword Suggestions": [
                    "Data Modeling",
                    "Data Cleansing",
                    "Data Governance",
                    "Business Intelligence",
                    "Data Warehousing",
                    "DWH",
                    "ETL",
                    "Agile Methodology",
                    "Scrum",
                    "Project Management"
                ]
            }
        }
    };

    return (
      <>
        <Header/>
        <Container id="features" sx={{ p: { xs: 4, sm: 2 }, pb: 10 }}>
          <Button variant="contained" color="primary" className={classes.backButton} onClick={() => navigate("/")}>
              Back
          </Button>
          <Typography variant="h4" gutterBottom>
              Resume ATS Check Results
          </Typography>
          <Box className={classes.root}>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h6" className={classes.sectionTitle}>ATS Compatibility Score</Typography>
                      <LinearProgress variant="determinate" value={parseInt(data.summary["ATS Compatibility Score"])} className={classes.progress} />
                      <Typography variant="body1">{data.summary["ATS Compatibility Score"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Keyword Metrics</Typography>
                      <Typography variant="body1">Keyword Density: {data.summary["Keyword Metrics"]["Keyword Density"]}</Typography>
                      <Typography variant="body1">Keyword Relevance: {data.summary["Keyword Metrics"]["Keyword Relevance"]}</Typography>
                      <Typography variant="body1">Top Keywords:</Typography>
                      <Box>
                          {data.summary["Keyword Metrics"]["Top Keywords"].map((keyword, index) => (
                              <Chip key={index} label={keyword} className={classes.chip} />
                          ))}
                      </Box>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Resume Structure and Format</Typography>
                      <Typography variant="body1">Clear Headings: {data.summary["Resume Structure and Format"]["Clear Headings"]}</Typography>
                      <Typography variant="body1">Bullet Point Usage: {data.summary["Resume Structure and Format"]["Bullet Point Usage"]}</Typography>
                      <Typography variant="body1">White Space Usage: {data.summary["Resume Structure and Format"]["White Space Usage"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Content and Relevance</Typography>
                      <Typography variant="body1">Work Experience Relevance: {data.summary["Content and Relevance"]["Work Experience Relevance"]}</Typography>
                      <Typography variant="body1">Skill Match: {data.summary["Content and Relevance"]["Skill Match"]}</Typography>
                      <Typography variant="body1">Education Relevance: {data.summary["Content and Relevance"]["Education Relevance"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Readability and Clarity</Typography>
                      <Typography variant="body1">Readability Score: {data.summary["Readability and Clarity"]["Readability Score"]}</Typography>
                      <Typography variant="body1">Sentence Complexity: {data.summary["Readability and Clarity"]["Sentence Complexity"]}</Typography>
                      <Typography variant="body1">Jargon and Acronyms: {data.summary["Readability and Clarity"]["Jargon and Acronyms"]}</Typography>
                  </CardContent>
              </Card>

              <Card className={classes.card}>
                  <CardContent>
                      <Typography variant="h5" className={classes.sectionTitle}>Actionable Tips and Suggestions</Typography>
                      <Typography variant="body1">Improvement Suggestions:</Typography>
                      <List>
                          {data.summary["Actionable Tips and Suggestions"]["Improvement Suggestions"].map((tip, index) => (
                              <ListItem key={index} className={classes.listItem}>
                                  <ListItemText primary={tip} classes={{ primary: classes.listItemText }} />
                              </ListItem>
                          ))}
                      </List>
                      <Typography variant="body1">Keyword Suggestions:</Typography>
                      <Box>
                          {data.summary["Actionable Tips and Suggestions"]["Keyword Suggestions"].map((keyword, index) => (
                              <Chip key={index} label={keyword} className={classes.chip} />
                          ))}
                      </Box>
                  </CardContent>
              </Card>
          </Box>
        </Container>
      </>
    );
};

export default ATSCheckResult;