import { Box, Typography } from "@mui/material";

const NewUpdateHeader = () => {
    return (
        <Box className="new-update-header">
            <Box className="text-container"><Typography component="span" className="bold-text">We have just launched a new feature!</Typography> <Typography component="span" className="normal-text">Checkout the</Typography> <Typography component="span" className="underline-text">new dashboard</Typography></Box>
        </Box>
    );
};

export default NewUpdateHeader;