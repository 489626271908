import { ILoggedinUserData } from '../components/utils';
import { SET_EXTRACTED_DATA, SET_ACCESS_TOKEN, SET_SELECTED_TEMPLATE_ID, SET_LOGGED_IN_USER_DATA, LOGOUT_USER, SET_COLOR_DATA } from './actionTypes';

export const setExtractedData = (data: string) => ({
  type: SET_EXTRACTED_DATA,
  payload: data,
});

export const setColorData = (data: string) => ({
  type: SET_COLOR_DATA,
  payload: data,
});

export const setLoggedInUserData = (data: ILoggedinUserData) => ({
  type: SET_LOGGED_IN_USER_DATA,
  payload: data,
});

export const setAccessToken = (token: string) => ({
  type: SET_ACCESS_TOKEN,
  payload: token,
});

export const setSelectedTemplateId = (templateId: string) => ({
  type: SET_SELECTED_TEMPLATE_ID,
  payload: templateId,
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});