import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken, setLoggedInUserData } from '../../redux/actions';
import { Alert, Box, Button, Container, CssBaseline, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ReactComponent as LinkedInIcon } from "../../assets/SVG/linkedIn-icon-blue.svg";
import { GoogleIcon } from '../Icons';
import { InputFieldCustomeStyle } from "../../ProcessResume/EditForms/ProcessResumeUtils";
import { saveToken } from './storeToken';

export interface SnackBarState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'warning' | 'info';
}

const CreateAccount = () => {

  const location = useLocation();
  const navigate = useNavigate();
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBar, setSnackBar] = useState<SnackBarState>({open: false, message: "", severity: "success"});

  const dispatch = useDispatch();
  // const accessToken = useSelector((state:any) => state.accessToken);
  const accessToken = useSelector((state:any) => state.accessToken);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleSocialLogin = async (provider: string) => {
    try {
      debugger
      const response = await fetch(`${process.env.REACT_APP_API_URL}/authorize?provider=${provider}`, { method: 'GET' });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      window.location.href = data.authorizeUrl+'&provider='+provider;
  
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  const handleCloseSnackbar = () => {
    setSnackBar({
      ...snackBar,
      open: false
    });
  };

  // Login form submission to {process.env.REACT_APP_API_URL}/login POST endpoint
  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      // const encryptedPassword = CryptoJS.AES.encrypt(formData.password, "1234567890123456").toString();
      console.log(process.env.REACT_APP_API_URL);
      const response = await fetch(`https://testapi.tekno.ai/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, /* password: encryptedPassword */ }),
      });

      if (response.ok) {
        const data = await response.json();
        dispatch(setLoggedInUserData(data));
        //storing token to local storage
        saveToken(data.token);
        navigate('/', { state: { isLoginSuccessful: true } });
      } else if (response.status === 401) {
        console.error('Login failed: Invalid credentials');
        setSnackBar({
          open: true,
          severity: "error",
          message: 'Invalid credentials. Please try again.'
        });
      } else {
        const errorData = await response.json();
        console.error('Login failed:', errorData.message);
        setSnackBar({
          open: true,
          severity: "error",
          message: 'Login failed. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  useEffect(() => {
    if (location.state?.fromRegistration) {
      setSnackBar({
        open: true,
        severity: "success",
        message: 'Registration successful. Please log in.'
      });
    }
    if (location.search) {
      const signInCallback = async () => {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');
        const provider = "LinkedIn";
        if (code && state /* && !accessToken */) {
          try {
            // Adjust the API endpoint or parameters as needed
            const response = await fetch(`${process.env.REACT_APP_API_URL}/signin-callback?provider=${provider}&code=${code}&state=${state}`, { method: 'GET' });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const accessToken = await response.json();
            dispatch(setAccessToken(accessToken));
            navigate('/', { state: { isLoginSuccessful: true } });
          } catch (error) {
            console.error('There was an error!', error);
          }
        }
        console.log("Access Token: ", accessToken);
      };
      signInCallback();
    };
  }, [location]);

 return (
    <Box className="create-account-container">
      <Container component="div" maxWidth="xs" className="create-account-wrapper">
          <CssBaseline />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding:"1rem",
            }}
          >
            <Typography component="h3" variant="h6" sx={{fontWeight:"600",fontSize:"1.85rem"}} >
              Login
            </Typography>
            <Box component="form" noValidate onSubmit={submitForm} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" component="label" htmlFor="email" gutterBottom>Email</Typography>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter your Email"
                    sx={InputFieldCustomeStyle}
                    inputProps={{
                      sx:{
                          height:"1rem"
                      }
                    }}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" component="label" htmlFor="password" gutterBottom>Password</Typography>
                  <TextField
                    required
                    fullWidth
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your Password"
                    sx={InputFieldCustomeStyle}
                    inputProps={{
                      sx:{
                          height:"1rem"
                      }
                    }}
                    onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="large"
                sx={{ mt: 3, mb: 2, fontWeight:"600", borderRadius:"10px", textTransform:"none", fontSize:"1rem"}}
              >
                Login
              </Button>
              <Box mb={2}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  className="social-login-button"
                  style={{ backgroundColor: '#fff', color: '#000', textTransform: "none",borderRadius:"10px" }}
                  startIcon={<LinkedInIcon />}
                  onClick={() => handleSocialLogin("LinkedIn")}
                >
                  Sign up with LinkedIn
                </Button>
              </Box>
              <Box mb={2}>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  className="social-login-button"
                  style={{ backgroundColor: '#fff', color: '#000', textTransform: "none",borderRadius:"10px" }}
                  startIcon={<GoogleIcon />}
                  onClick={() => handleSocialLogin("Google")}
                >
                  Sign up with Google
                </Button>
              </Box>
              <Box mb={2} sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                <Link onClick={() => navigate('/register')} to={'/register'}>
                  New User? Register here
                </Link>
              </Box>
            </Box>
          </Box>
      </Container>
      <Snackbar open={snackBar.open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackBar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBar.message}
        </Alert>
      </Snackbar>
  </Box>
 )
}

export default CreateAccount;