export const InputFieldCustomeStyle = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      backgroundColor:"#fff",
      '& fieldset': {
        borderRadius: '10px',
        border:"1px solid lightgray",
        // backgroundColor:"#fff",
      },
    },
  }

 export const InputSelectCustomeStyle = {
    '& .MuiSelect-select': {
       backgroundColor:"#fff",
       color:"grey",
      '& fieldset': {
        // backgroundColor:"#fff",
      },
    },  
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'lightgrey', // Default border color
        borderRadius: '10px',
      },       
  }