import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import { styled } from '@mui/material/styles';
interface AccordionCustomeProps {
    heading: string;
    content: string;
}

const CustomAccordion:React.FC<AccordionCustomeProps> = ({heading,content}) => {
  const [expanded, setExpanded] = useState<boolean|string>(false);

  const handleChange = (isExpanded:any) => () => {
    setExpanded(isExpanded ? 'panel1' : false);
  };


  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleChange(!expanded)}
    sx={{boxShadow:"none",borderBottom: "1px solid lightgray",borderRadius:"0",margin:"0 !important"}}
    >
      <AccordionSummary
        expandIcon={expanded ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{borderRadius:"none",margin:"0 !important",'& .MuiAccordionSummary-content': {
            margin:"22px 0",
            '&.Mui-expanded': {
                margin: '20px 0 0 0',
              },
          },}}
      >
        <Typography component="h6" variant="body1" sx={{fontWeight:"500",fontSize:"18.5px"}}>{heading}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{marginBottom:"1rem"}}>
        <Typography sx={{color:"#475367",fontWeight:"400",fontSize:"17px"}}>
          {content}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
