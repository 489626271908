export const GoogleIcon = () => (
  <svg width="20" height="19" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1081_468)">
      <path d="M24.5 12.3808C24.5 11.5843 24.4311 10.7836 24.2842 10H12.5V14.5119H19.2483C18.9682 15.9671 18.0685 17.2544 16.751 18.0724V21H20.777C23.1412 18.9593 24.5 15.9456 24.5 12.3808Z" fill="#4285F4" />
      <path d="M12.4084 24C15.7007 24 18.4771 22.9045 20.5 21.0136L16.5663 17.9227C15.4719 18.6773 14.059 19.1045 12.4129 19.1045C9.22828 19.1045 6.52809 16.9273 5.55925 14H1.5V17.1864C3.57224 21.3636 7.79297 24 12.4084 24Z" fill="#34A853" />
      <path d="M5.5 14.1318C5.02241 12.7488 5.02241 11.2512 5.5 9.86825V7H1.71282C0.0957268 10.1465 0.0957268 13.8535 1.71282 17L5.5 14.1318Z" fill="#FBBC04" />
      <path d="M12.3648 4.89366C14.0982 4.8664 15.7735 5.52968 17.0288 6.7472L20.5 3.21729C18.302 1.11841 15.3848 -0.0355109 12.3648 0.000833182C7.76781 0.000833182 3.56395 2.63578 1.5 6.81535L5.53856 10C6.49906 7.06976 9.19292 4.89366 12.3648 4.89366Z" fill="#EA4335" />
    </g>
    <defs>
      <clipPath id="clip0_1081_468">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export const LinkedInIcon = () => (
  <svg width="20" height="19" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1081_474)">
      <path d="M5.53645 2.66782C5.53611 3.37503 5.27048 4.05313 4.79799 4.55294C4.32549 5.05276 3.68484 5.33336 3.01696 5.33301C2.34909 5.33265 1.7087 5.05138 1.23668 4.55106C0.764658 4.05074 0.499666 3.37236 0.5 2.66516C0.500334 1.95795 0.765967 1.27985 1.23846 0.780032C1.71096 0.280213 2.35161 -0.000383786 3.01948 -3.01838e-05C3.68736 0.000323419 4.32774 0.281598 4.79977 0.781918C5.27179 1.28224 5.53678 1.96062 5.53645 2.66782ZM5.61199 7.30756H0.575547V24H5.61199V7.30756ZM13.5696 7.30756H8.55832V24H13.5192V15.2405C13.5192 10.3607 19.5252 9.90742 19.5252 15.2405V24H24.4987V13.4272C24.4987 5.20101 15.6093 5.50766 13.5192 9.54744L13.5696 7.30756Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_1081_474">
        <rect width="24" height="24" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
);