import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { InputFieldCustomeStyle } from './ProcessResumeUtils';
import {ReactComponent as DatePickerIcon} from "../../assets/SVG/date-picker.svg";
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SelectChangeEvent } from "@mui/material";
import EducationCard from "./EducationCard";
import { useDispatch, useSelector } from "react-redux";
import { setExtractedData } from "../../redux/actions";

const DEFAULT_FORM_DATA = {
  startDate: null,
  startDate_date: null,
  endDate: null,
  endDate_date: null,
  degree: '',
  gpa: '',
  specialization: '',
  universityCollege: '',
};

const Education = () => {
    const navigate = useNavigate();
    const [jobType, setJobType] = useState<string[]>(["fullTime"]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [errors,setErrors] = useState({
      universityCollege: "",
      degree:"",
      gpa:"",
      startDate:"",
      endDate:""
    });

    const [editingIndex, setEditingIndex] = useState(null as null | number);
    const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

    const dispatch = useDispatch();
    const extractedData = useSelector((state:any) => state.extractedData);

    const handleChange = (newValue: string | Date | null, field: string) => {
      if (newValue instanceof Date) {
        const formattedDate = newValue.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
        const date = new Date(newValue);
        const dateAsValue = date.toISOString().split('T')[0]
        setFormData((prevData) => ({
          ...prevData,
          [field]: formattedDate,
          [`${field}_date`]: dateAsValue
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [field]: newValue,
        }));
      }
    };

    useEffect(() => {
      if (editingIndex !== null) {
        setFormData(extractedData.educationalDetails[editingIndex]);
      }
      setErrors({
        universityCollege: "",
        degree:"",
        gpa:"",
        startDate:"",
        endDate:""
      })
    }, [editingIndex]);

    const deleteEducation = (index: number) => {
      dispatch(setExtractedData({
        ...extractedData,
        educationalDetails: extractedData.educationalDetails.filter((item: any, i: number) => i !== index),
      }));
    }

    useEffect(() => {
      dispatch(setExtractedData({
        ...extractedData,
        educationalDetails: extractedData.educationalDetails.map((item: any, index: number) => {
          if (index === editingIndex) {
            return formData;
          }
          return item;
        }),
      }));
    }, [formData]);

    const handleAddEducation = () => {
      setEditingIndex(extractedData.educationalDetails.length);
      dispatch(setExtractedData({
        ...extractedData,
        educationalDetails: [
          ...extractedData.educationalDetails,
          DEFAULT_FORM_DATA
        ],
      }));
    }

  const handleSave = () => {
      if(!formData.universityCollege) {
        setErrors((prev)=>({
          ...prev,
          universityCollege:"University is required"
        }))
      }
      if(!formData.degree) {
        setErrors((prev)=>({
          ...prev,
          degree:"degree is required"
        }))
      }
      if(!formData.gpa) {
        setErrors((prev)=>({
          ...prev,
          gpa:"GPA is required"
        }))
      }
      if(!formData.startDate){
        setErrors((prev)=>({
          ...prev,
          startDate:"Start Date is required"
        }))
      }
      if(!formData.endDate) {
        setErrors((prev)=>({
          ...prev,
          endDate:"End Date is required"
        }))
      }
      if(formData.universityCollege && formData.degree && formData.gpa && formData.startDate && formData.endDate) {
        setEditingIndex(null)
      }
  }

    return (
        <Box className="education-container">
            <Box className="education-wrapper">
              <Box className="content">
                <Box className="main-heading">
                  <Box className="heading-box">
                    <Typography variant="h5" color="color.primary" className="heading">
                        Education
                    </Typography>
                  </Box>
                  <Box className="page-indicator-box">
                    <Typography variant="h5" color="color.primary" className="page-indicator">
                        3 of 5
                    </Typography>
                  </Box>
                </Box>
                {
                  editingIndex !== null ? (
                    <Box className="full-time">
                      <Box className="input-box-container">
                          <Typography variant="body2" component="label" htmlFor="instituitionName" gutterBottom>
                              Instituition Name
                          </Typography>
                          <TextField
                              fullWidth
                              id="instituitionName"
                              sx={InputFieldCustomeStyle}
                              placeholder="Ex: Harvard University"
                              value={formData?.universityCollege}
                              onChange={(e) => handleChange(e.target.value, 'universityCollege')}
                              error={!!errors.universityCollege}
                              helperText={errors.universityCollege ? errors.universityCollege : ""}
                            />
                      </Box>

                      <Box className="input-box-container">
                          <Typography variant="body2" component="label" htmlFor="degree" gutterBottom>
                              Degree
                          </Typography>
                          <TextField
                              fullWidth
                              id="degree"
                              sx={InputFieldCustomeStyle}
                              placeholder="Ex: Computer Science"
                              value={formData?.degree}
                              onChange={(e) => handleChange(e.target.value, 'degree')}
                              error={!!errors.degree}
                              helperText={errors.degree ? errors.degree : ""}
                            />
                      </Box>
                      
                      <Box className="input-box-container">
                          <Typography variant="body2" component="label" htmlFor="gpa" gutterBottom>
                              GPA
                          </Typography>
                          <TextField
                              fullWidth
                              id="gpa"
                              sx={InputFieldCustomeStyle}
                              placeholder="Ex: 4.0"
                              value={formData?.gpa}
                              onChange={(e) => handleChange(e.target.value, 'gpa')}
                              error={!!errors.gpa}
                              helperText={errors.gpa ? errors.gpa : ""}
                            />
                      </Box>

                      <Box className="date-picker-container">
                          <Box className="date-picker-wrapper">
                            <Typography variant="body2" component="label" htmlFor="startDate" gutterBottom>Start Date</Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                value={formData?.startDate}
                                onChange={(newValue) => handleChange(newValue, 'startDate')}
                                slots={{
                                  openPickerIcon: DatePickerIcon,
                                  textField: (params) => (
                                    <TextField
                                      {...params}
                                      value={formData?.["startDate_date"]}
                                      id="startDate"
                                      sx={InputFieldCustomeStyle}
                                      error={!!errors.startDate}
                                      helperText={errors.startDate ? errors.startDate : ""}
                                    />
                                  ),
                                }}
                              />
                            </LocalizationProvider>
                          </Box>

                          <Box className="date-picker-wrapper">
                              <Typography variant="body2" component="label" htmlFor="endDate" gutterBottom>
                                End Date
                              </Typography>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                value={formData?.endDate}
                                onChange={(newValue) => handleChange(newValue, 'endDate')}
                                minDate={formData?.startDate ? new Date(formData.startDate) : undefined}
                                slots={{
                                  openPickerIcon: DatePickerIcon,
                                  textField: (params) => (
                                    <TextField
                                      {...params}
                                      value={formData?.["endDate_date"]}
                                      id="endDate"
                                      sx={InputFieldCustomeStyle}
                                      error={!!errors.endDate}
                                      helperText={errors.endDate ? errors.endDate : ""}
                                    />
                                  ),
                                }}
                              />
                          </LocalizationProvider>
                          </Box>
                      </Box>

                      <Box className="input-box-container">
                          <Typography variant="body2" component="label" htmlFor="address" gutterBottom>
                            Description
                          </Typography>
                          <TextField
                            multiline
                            rows={4}
                            fullWidth
                            value={formData.specialization}
                            id="address"
                            sx={InputFieldCustomeStyle}
                            placeholder="Ex: +91 123456789"
                            onChange={(e) => handleChange(e.target.value,"specialization")}
                          />
                      </Box>

                      <Box className="input-box-container">
                        <Button variant="contained" size="large" color="primary" onClick={handleSave}>Save</Button>
                      </Box>

                    </Box>
                  ) : 
                  extractedData.educationalDetails && extractedData.educationalDetails.map((educationDetail: any, index: number) => (
                    <EducationCard
                      details={{
                        heading: educationDetail.degree,
                        subHeading: educationDetail.universityCollege,
                        startDate: educationDetail.startDate,
                        endDate: educationDetail.endDate,
                        city: educationDetail.gpa,
                        aboutText: educationDetail.specialization,
                      }}
                      handleEditClick={() => setEditingIndex(index)}
                      handleDeleteClick={() => deleteEducation(index)}
                    />
                  ))
                }

                <Box className="add-work-exp">
                  <Typography variant="body2" className="color-primary" onClick={handleAddEducation}>Add Education</Typography>
                </Box>
              </Box>
            </Box>
        </Box>
    )

}

export default Education;