import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Link as MuiLink,
  Grid,
  Avatar,
  CssBaseline,
  Link,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import { LinkedIn, Google, LockOutlined } from '@mui/icons-material';
import { GoogleIcon, LinkedInIcon } from '../Icons';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const Login = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    country: "",
    password: ""
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const encryptedPassword = CryptoJS.AES.encrypt(formData.password, "1234567890123456").toString();

      const response = await fetch(`${process.env.REACT_APP_API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, /* password: encryptedPassword */ }),
      });

      if (response.ok) {
        navigate('/login', { state: { fromRegistration: true } });
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Registration failed. Please try again.');
        setOpen(true);
      }
    } catch (error) {
      console.error('Error during registration:', error);
      setErrorMessage('An error occurred. Please try again later.');
      setOpen(true);
    }
  };

  const handleClose = (event: any) => {
    setOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
        <Snackbar open={open} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Box component="form" action="post" onSubmit={submitForm} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone"
                  type="number"
                  id="phone"
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="country"
                  label="Country"
                  type="text"
                  id="country"
                  onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Register
            </Button>
            <Box mb={2}>
              <Divider variant="middle"/>
            </Box>
            <Box mb={2}>
              <Button
                variant="contained"
                fullWidth
                className="social-login-button"
                style={{ backgroundColor: '#2D64BC', color: '#fff', textTransform: "none" }}
                // onClick={() => handleSocialLogin("LinkedIn")}
                startIcon={<LinkedInIcon />}
              >
                Register with LinkedIn
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                fullWidth
                className="social-login-button"
                style={{ textTransform: "none" }}
                // onClick={() => handleSocialLogin("Google")}
                startIcon={<GoogleIcon />}
              >
                Register with Google
              </Button>
            </Box>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link onClick={() => navigate('/process-resume/personal-details')} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
    </Container>
  );
}

export default Login;