import  Box  from '@mui/material/Box';
import  Paper  from '@mui/material/Paper';
import ProcessResumePreviewHeader from './ProcessResumePreviewHeader';
import ProcessResumePreview from './ProcessResumePreview';
import ResumeScoreProgressBar from './ResumeScoreProgressBar';
import { Routes, Route, useNavigate } from 'react-router-dom';
import UploadProfilePhoto from './EditForms/UploadProfilePhoto';
import PersonalDetails from './EditForms/PersonalDetails';
import WorkExperience from './EditForms/WorkExperience';
import Education from './EditForms/Education';
import Skills from './EditForms/Skills';
import { Button } from '@mui/material';
import ProcessResumeFooter from './ProcessResumeFooter';
import Header from '../components/common/Header';

const ProcessResumeFeature:React.FC = () => {

    return (
      <>
        <Header />
        <Box className="process-resume-page-container">
          <Paper className="left-edit-container">
            <Box className="left-progress-bar">
              <ResumeScoreProgressBar/>
            </Box>
            <Box className="left-edit-fields-container">
              <Routes>
                  <Route path="profile-photo" element={<UploadProfilePhoto/>} />
                  <Route path="personal-details" element={<PersonalDetails/>} />
                  <Route path="work-experience" element={<WorkExperience/>} />
                  <Route path="education" element={<Education/>} />
                  <Route path="skills" element={<Skills/>} />
              </Routes>
            </Box>
            
            <ProcessResumeFooter/>
          </Paper>

          <Box className="right-preview-container">
              <Box className="right-preview-header-container">
                  <ProcessResumePreviewHeader/>
              </Box>
              <Box className="right-preview-content-container">
                  <ProcessResumePreview/>
              </Box>
          </Box>
        </Box>
      </>
    )
}

export default ProcessResumeFeature;