export interface IResume {
  name: {
    firstName: string;
    lastName: string;
  };
  profile_Picture: null;
  email: string;
  phone: string[];
  location: string;
  socialLinks: {
    personal_Website: string;
    linkedIn_URL: string;
    github_URL: string;
    twitter_X_URL: null;
  };
  careerObjective: string;
  designation: string;
  educationalDetails: IEducationalDetail[];
  workExperiences: IWorkExperience[];
  projects: IProject[];
  certifications: string[];
  toolsTechnicalSkills: string[];
  programmingLanguages: string[];
  interests: string[];
}

export interface IEducationalDetail {
  universityCollege: string;
  degree: string;
  specialization: string;
  gpa: string;
  startDate: string;
  endDate: string;
}

export interface IWorkExperience {
  startDate: string;
  endDate: string;
  company: string;
  role: string;
  keyResponsibilities: string;
  projectDetails: string;
  toolsTechnologies: string;
}

export interface IProject {
  title: string;
  description: string;
  toolsTechnologies: string;
}

export interface ILoggedinUserData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  country: string;
  password: string;
};

export const isValueExist = (field:string):boolean => {
  if(field && field!=="NA") {
    return true;
  }
  return false;
}

export enum TemplateCategory {
  Design = "Design",
  Development = "Development",
  Marketing = "Marketing",
  Sales = "Sales",
  HumanResources = "Human Resources",
  Testing = "Testing",
  Business = "Business"
}

export const Templates = [
  {
      id: "1",
      name: "Template 1",
      tag: TemplateCategory.Design,
      img: require("./../templates/preview/T1.png")
  },
  {
      id: "2",
      name: "Template 2",
      tag: TemplateCategory.Design,
      img: require("./../templates/preview/T2.png")
  },
  {
      id: "3",
      name: "Template 3",
      tag: TemplateCategory.Development,
      img: require("./../templates/preview/T3.png")
  },
  {
      id: "4",
      name: "Template 4",
      tag: TemplateCategory.Sales,
      img: require("./../templates/preview/T4.png")
  },
  {
      id: "5",
      name: "Template 5",
      tag: TemplateCategory.Marketing,
      img: require("./../templates/preview/T2.png")
  },
  {
      id: "6",
      name: "Template 6",
      tag: TemplateCategory.Testing,
      img: require("./../templates/preview/T3.png")
  },
  {
    id: "7",
    name: "Template 7",
    tag: TemplateCategory.Testing,
    img: require("./../templates/preview/T7.png")
},
{
  id: "8",
  name: "Template 8",
  tag: TemplateCategory.Testing,
  img: require("./../templates/preview/T6.png")
},
{
  id: "9",
  name: "Template 9",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T9.png")
},
{
  id: "10",
  name: "Template 10",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T10.png")
},
{
  id: "11",
  name: "Template 11",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T11.png")
},
{
  id: "12",
  name: "Template 12",
  tag: TemplateCategory.Marketing,
  img: require("./../templates/preview/T12.png")
},
{
  id: "13",
  name: "Template 13",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T13.png")
},
{
  id: "14",
  name: "Template 14",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T14.png")
},
{
  id: "15",
  name: "Template 15",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T15.png")
},
{
  id: "16",
  name: "Template 16",
  tag: TemplateCategory.Business,
  img: require("./../templates/preview/T16.png")
},
]